import 'semantic-ui-css/components/dropdown';
import $ from 'jquery';

$.fn.extend({
    contactFormAutoComplete() {
        this.each((idx, el) => {
            const element = $(el);
            const criteriaName = element.data('criteria-name');
            const choiceName = element.data('choice-name');
            const choiceValue = element.data('choice-value');
            const autocompleteValue = element.find('input.autocomplete').val();
            const loadForEditUrl = element.data('load-edit-url');

            const selectedValue = element.data('selected-value');
            const selectedName = element.data('selected-name');
            const fieldName = element.data('nfq-contact-autocomplete-field');

            const values = !selectedValue ? false : [{
                name: selectedName,
                value: selectedValue,
                selected: true
            }];

            element.dropdown({
                delay: {
                    search: 250,
                },
                values: values,
                forceSelection: false,
                clearable: true,
                apiSettings: {
                    dataType: 'JSON',
                    cache: false,
                    beforeSend(settings) {
                        /* eslint-disable-next-line no-param-reassign */
                        settings.data[criteriaName] = settings.urlData.query;

                        const baseContactDiv = element.parent().parent();
                        const originType = baseContactDiv.find('.base-sale-origin');

                        if (fieldName === 'pickupContact' && $('.pickup-region-field')) {
                            settings.data['region'] = $('.pickup-region-field').val();
                        }

                        if (fieldName === 'deliveryContact' && $('.delivery-region-field')) {
                            settings.data['region'] = $('.delivery-region-field').val();
                        }

                        if (originType.length > 0) {
                            settings.data['originType'] = originType.val() || 'auction';
                        }

                        const companyField = fieldName === 'pickupContact'
                            ? $('[data-poster-company-field]')[0]
                            : $('[data-delivery-company-field]')[0];
                        const companyId = companyField ? companyField.value : null;
                        if (companyId) {
                            settings.data['companyId'] = companyId;
                        }

                        return settings;
                    },
                    onResponse(response) {
                        const results = response.map(item => ({
                            name: item[choiceName],
                            value: item[choiceValue],
                        }));

                        if (results.length === 0) {
                            element.dropdown('setup menu', []);
                        }

                        return {
                            success: true,
                            results: results,
                        };
                    },
                },
            });

            if (autocompleteValue.split(',').filter(String).length > 0) {
                const menuElement = element.find('div.menu');

                menuElement.api({
                    on: 'now',
                    method: 'GET',
                    url: loadForEditUrl,
                    beforeSend(settings) {
                        /* eslint-disable-next-line no-param-reassign */
                        settings.data[choiceValue] = autocompleteValue.split(',').filter(String);

                        return settings;
                    },
                    onSuccess(response) {
                        response.forEach((item) => {
                            menuElement.append((
                                $(`<div class="item" data-value="${item[choiceValue]}">${item[choiceName]}</div>`)
                            ));
                        });
                    },
                });
            }

            window.setTimeout(() => {
                element.dropdown('set selected', element.find('input.autocomplete').val().split(',').filter(String));
            }, 5000);
        });
    },
});
