import $ from 'jquery';

export function companyDocumentRejectModalInitializer() {
    $(document).ready(function() {
        $('.reject-document-modal').click(function() {
            $('#document-modal-'+$(this).data('document-modal-id')).modal('show');
        })

        $('.view-document-modal').click(function() {
            $('#document-modal-'+$(this).data('document-modal-id')).modal('show');
        })

        $('.cancel-reject-document').click(function() {
            $('#document-modal-'+$(this).data('document-cancel-id')).modal('hide');
        })

        $('.view-reject-document').click(function() {
            $('#document-modal-'+$(this).data('document-view-id')).modal('hide');
        })

        $('.submit-reject-document').click(function() {
            let form = $('#document-form-'+$(this).data('document-submit-id'));
            if (form.find('input[name="reason"]').val().length > 0) {
                form.submit();
            }
        })
    });
}
