import $ from 'jquery';

export function cargoRoutePriceAutofill() {
    let pickupLocationField = $('#cargo_pickupContact');
    let deliveryLocationField = $('#cargo_deliveryContact');
    let bodyTypeField = $('.body-type-field');

    pickupLocationField.on('change', function() {
        getPrices();
    });
    deliveryLocationField.on('change', function() {
        getPrices();
    });
    bodyTypeField.on('change', function() {
        getPrices();
    });
}

function getPrices()
{
    let pickupLocation = $('#cargo_pickupContact').val();
    let deliveryLocation = $('#cargo_deliveryContact').val();
    let bodyType = $('.body-type-field').val();

    if (pickupLocation.length === 0 || deliveryLocation.length === 0 || bodyType.length === 0) {
        return;
    }

    let carrierPriceField = $('.carrier-payment-field');
    let posterPriceField = $('.poster-payment-field');

    $.ajax({
        url: `/route-prices/ajax/prices?bodyType=${bodyType}&pickup=${pickupLocation}&delivery=${deliveryLocation}`,
        method: 'GET',
        success: function(data) {
            if (data.carrier_price) {
                carrierPriceField.val(data.carrier_price)
            }

            if (data.poster_price) {
                posterPriceField.val(data.poster_price)
            }
        }
    });
}
