import $ from 'jquery';

export function editAntRequestModal() {
    $('#editAndRequestLink').on('click', function (e) {
        e.preventDefault();

        const url = $(this).attr('href');

        const modal = $('<div class="ui modal"><div class="ui active dimmer"><div class="ui loader"></div></div></div>');
        $('body').append(modal);

        $.ajax({url: url,
            success: function (response) {
                $(modal).html(response);
            },
        });

        modal.modal({
            onHidden: function () {
                $(this).remove();
            },
            onApprove: function() {
                const form = $(modal).find('form')[0];
                const formData = new FormData(form);

                $.ajax({
                    url: form.action,
                    method: form.method,
                    data: formData,
                    processData: false,
                    contentType: false,
                    statusCode: {
                        200: function (response) {
                            $(modal).html(response);
                        },
                        201: function (response) {
                            $(modal).modal('hide');
                            const currentUrl = new URL(window.location.href);
                            const searchParams = new URLSearchParams(currentUrl.search);
                            searchParams.set('tab', 'requests');
                            currentUrl.search = searchParams.toString();
                            window.location.href = currentUrl.toString();
                        }
                    }
                });

                return false;
            }
        }).modal('show');
    });
}
