import $ from 'jquery';

export function showEditAndRequest() {
    $(document).ready(function() {
        $('.order-information-tabs__item').on('click', function() {
            let tab = $(this).data('tab');

            if (tab === 'order_requests') {
                $('#editAndRequest').show();
            } else {
                $('#editAndRequest').hide();
            }
        });
    });
}
