import $ from 'jquery';

export function orderRequestedInvalidDocumentsModal() {
    $(document).ready(function() {
        $('#order-requested-invalid-documents-modal').modal('show');
        $('#order-requested-invalid-documents-modal .close').on('click', function() {
            $('#order-requested-invalid-documents-modal').modal('hide');
        });
    });
}
