import $ from 'jquery';

export function companyPriceOnTopInitializer() {
    let enabled = $("#company-edit-form").length === 1;

    if (enabled) {
        let $companyTypeSelect = $("#company_type");
        let availableCompanyTypes = [
            'dealer',
            'broker'
        ];

        let $priceOnTop = $("#company_priceOnTop");

        if ($.inArray($companyTypeSelect.val(), availableCompanyTypes) !== -1) {
            $priceOnTop.removeAttr('disabled');
            $priceOnTop.closest(".field").show();
        } else {
            $priceOnTop.val("0.00").attr('disabled','disabled');
            $priceOnTop.closest(".field").hide();
        }

        if($priceOnTop.length === 1) {
            $companyTypeSelect.on("change", function () {
                if ($.inArray(this.value, availableCompanyTypes) !== -1) {
                    $priceOnTop.removeAttr('disabled');
                    $priceOnTop.closest(".field").show();
                } else {
                    $priceOnTop.val("0.00").attr('disabled','disabled');
                    $priceOnTop.closest(".field").hide();
                }
            });
        }
    }
}
